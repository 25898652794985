@import './carbon-config.scss';

@import './fonts.scss';
@import './variables.scss';
@import './scrollbar.scss';
@import 'rc-slider/assets/index.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  box-sizing: border-box;
  -webkit-print-color-adjust: exact;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: var(--main-font) !important;
  font-variant-numeric: tabular-nums;
}

a {
  color: inherit;
}

.tree-namecard {
  padding-top: 40px;
  padding-bottom: 40px;
  z-index: 9999;
}

.tree-namecard .mapboxgl-popup-tip {
  display: none;
}

.tree-namecard .mapboxgl-popup-content {
  background-color: var(--table-background);
  color: var(--text-primary);
  box-shadow: 0 3px 3px rgba(0,0,0,0.2);
  font-size: var(--font-size-xs);
  padding: 0;
  border-radius: 7px;
  overflow: hidden;
}

.tree-namecard .mapboxgl-popup-close-button {
  color: inherit;
}

.managed-area-popup {
  z-index: 9999;
  padding-bottom: 5px;
}

.managed-area-popup-container {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.managed-area-popup .mapboxgl-popup-tip {
  border-color: #373737 transparent transparent transparent;
}

.managed-area-popup .mapboxgl-popup-content {
  background-color: #373737;
  color: var(--text-primary);
  font-size: var(--font-size-m);
  padding: 0;
  border-radius: 7px;
}

.treePopUpContainer .imageContainer .image.loading {
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  background-size: 200%;
  animation: moveBackground 1.2s ease infinite;
}

@keyframes moveBackground {
  0% {
    background-position: 0 0
  }
  50% {
    background-position: 100% 0
  }
  100% {
    background-position: 0 0
  }
}

.treePopUpContainer {
  width: 300px;
}

.treePopUpContainer .imageContainer {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  gap: 4px;
}

.treePopUpContainer .imageContainer::-webkit-scrollbar {
  display: none;
}

.treePopUpContainer .imageContainer .image {
  background-color: rgba(255, 255, 255, 0.1);
  width: 300px;
  height: 180px;
  border-radius: 2px;
  background-position: center center;
  background-size: cover;
}

.treePopUpContainer .mainContainer {
  position: relative;
  width: 100%;
  padding: 12px 16px;
}

.treePopUpContainer .mainExternalId {
  font-size: var(--font-size-s);
  margin: 0;
  line-height: 1.25rem;
  height: 1.25rem;
  font-weight: bold;
}

.treePopUpContainer .mainSpecies {
  font-size: var(--font-size-xs);
  line-height: 1.125rem;
  height: 1.125rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.treePopUpContainer .mainManagedArea {
  font-size: var(--font-size-xs);
  opacity: 0.72;
  line-height: 1.125rem;
  height: 1.125rem;
}

.treePopUpContainer .properties .property {
  font-weight: bold;
  line-height: 1.125rem;
}

.treePopUpContainer .detailsButton {
  position: absolute;
  top: 12px;
  right: 20px;
  display: flex;
  font-size: var(--font-size-s);
  align-items: center;
  padding: 5px 14px;
  gap: 6px;
  color: white;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  cursor: pointer;
}

.treePopUpContainer .detailsButton:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.treePopUpContainer .detailsButton img {
  width: 16px;
  height: 16px;
}

.treeClusterDonut {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.treeClusterLabel {
  background: var(--page-background);
  border-radius: 50%;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapboxgl-ctrl-bottom-right {
  z-index: 9999 !important;
}

.mapboxgl-ctrl-group{
  background-color: var(--table-background);
  margin-right: 60px !important;
  margin-bottom: 120px !important;
  z-index: 9999 !important;
  .mapboxgl-ctrl-zoom-in{
    width: 40px;
    height: 40px;
    .mapboxgl-ctrl-icon {
      background-image: url('plus.svg') !important;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.1) !important;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
}
  .mapboxgl-ctrl-zoom-out {
    border: none !important;
    width: 40px;
    height: 40px;
    .mapboxgl-ctrl-icon {
      background-image: url('minus.svg') !important;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
  }
  .mapboxgl-ctrl-compass {
    width: 40px;
    height: 40px;
    border-color: #394347;
    border-width: 2px;
    .mapboxgl-ctrl-icon {
      background-image: url('compass.svg') !important;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.1) !important;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.insight-map-container {
  .mapboxgl-ctrl-bottom-left {
    margin-bottom: 25px;
  }
}

.inlineMapWrapper {
  .mapboxgl-ctrl-bottom-right {
    right: 15px;
    bottom: 15px;

    button {
      width: 38px;
      height: 38px;
    }

    .mapboxgl-ctrl-group {
      margin-right: 0 !important;
      margin-bottom: 0 !important;
    }

    .mapboxgl-ctrl-compass {
      display: none;
    }
  }

  .mapboxgl-ctrl-bottom-left {
    bottom: 5px;
    left: 5px;
  }
}

.areaMap {
  .mapboxgl-ctrl-bottom-right {
    right: 15px;
    bottom: 15px;

    button {
      width: 34px;
      height: 34px;
    }

    .mapboxgl-ctrl-group {
      margin-right: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  .mapboxgl-ctrl-zoom-out:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.normalize-scrollbar {
  ::-webkit-scrollbar-track {
    background: initial !important;
  }
  ::-webkit-scrollbar-thumb {
    border: initial !important;
  }
  ::-webkit-scrollbar-corner {
    background: initial !important;
  }
}

.cds--accordion__content {
  padding: 0;
}

.cds--accordion__arrow {
  margin: 0 4px;
}

.cds--tooltip-content {
  padding: 4px 8px;
}

[data-carbon-theme='g10'], [data-carbon-theme='white'] {
  .light-input-background {
    .cds--dropdown, .cds--multi-select, .cds--text-input {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .cds--actionable-notification__action-button.cds--btn--tertiary {
    background: white !important;

    &:hover, &:focus {
      color: white !important;
      background: var(--cds-button-primary) !important;
    }
  }
}

[data-carbon-theme='g100'], [data-carbon-theme='g90'] {
  .light-input-background {
    .cds--dropdown, .cds--multi-select, .cds--text-input {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .cds--actionable-notification__action-button.cds--btn--tertiary {
    &:hover, &:focus {
      color: white !important;
    }
  }
}

.modal-action-buttons-with-gap {
  .cds--btn-set {
    background: var(--cds-panel);
    gap: 2px;
    .cds--btn:not(:focus) {
      box-shadow: none;
    }
  }
}

.tree-edit-modal {
  .cds--modal-container {
    max-width: 832px;
  }

  .cds--modal-content {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 16px;
    height: 100%;

    & > div:nth-child(2) > ul {
      max-height: fit-content;
      position: sticky;
      top: 0;
    }
  }

  .cds--modal-scroll-content {
    mask-image: none;
  }
}

#create-work-modal-container {
  .cds--modal-scroll-content {
    mask-image: none;
  }
}

.custom-layer-selector {
  .cds--checkbox-label{
    width: 100%;
    .cds--checkbox-label-text{
      width: 100%;
    }
  }
}
#urban-forest-layer-select {
  max-block-size: 3rem;
  block-size: 3rem;
}

.cds--data-table_inner-container{
  background: none;
}

.cds--data-table--selected{
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--table-selected-row-border-color) !important;
}

.cds--dropdown--disabled {
  cursor: not-allowed;
}
