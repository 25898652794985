.container {
  background-color: rgba(27, 36, 39, .9);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

.content {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.controls {
  position: fixed;
  right: 12px;
  top: 12px;
  z-index: 1;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.image {
  max-height: 90vh;
  max-width: 90vw;
  cursor: zoom-in;
}

.image.zoomedIn {
  cursor: zoom-out;
}

.closeButton {
  background: transparent;
  border: none;
  font-size: var(--font-size-xl);
  color: var(--text-primary);
  cursor: pointer;
}

.navigateButton {
  padding: 10px;
  margin: 10px;
  border-radius: 50%;
  svg {
    width: 26px;
    height: 26px;
  }
}

.buttonPlaceholder {
  width: 66px;
}

.highlightArrowButton {
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.2s ease-in-out;
}
